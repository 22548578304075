import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { axiosInstance } from "../../components/baseUrl.jsx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag.js";

const SellersRegistration = () => {
  const { t } = useTranslation();
  const { handleEventTag } = useEventTag();
  const [sellerRegisterDetail, setSellerRegisterDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    howDidYouHearAboutUs: "",
  });
  const [phoneValue, setPhoneValue] = useState("");
  const [formattedErrors, setFormattedErrors] = useState({});
  const [customError, setCustomError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(false);
  const referralCode = localStorage.getItem("referralCode");

  const handleChange = (e) => {
    setSellerRegisterDetail({
      ...sellerRegisterDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //htmlFor password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      // const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (!uppercasePassword) {
        errMsg = "At least one uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one special character";
      } else if (!minLengthPassword) {
        errMsg = "password should not be 8 characters ";
      } else {
        errMsg = "";
      }
      setPasswordError(errMsg);
    }
  };
  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const sellerRegistration = {
        firstName: sellerRegisterDetail.firstName,
        LastName: sellerRegisterDetail.lastName,
        email: sellerRegisterDetail.email,
        phoneNumber: phoneValue,
        role: "SELLER",
        password: sellerRegisterDetail.password,
        hearAboutUs: sellerRegisterDetail.howDidYouHearAboutUs,
      };
      if (referralCode) {
        const { data } = await axiosInstance.post(
          `/auth/register-user?referralCode=${referralCode}`,
          sellerRegistration
        );
        localStorage.setItem("pinId", data.pinId);
        toast.success("You have successfully signed up.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });

        localStorage.removeItem("referralCode");
        handleEventTag(`seller_registeration`, data?.data?.id);

        navigate(`/verify-phone-number`);
        localStorage.setItem("userData", JSON.stringify(data.data));
      } else {
        const { data } = await axiosInstance.post(
          `/auth/register-user`,
          sellerRegistration
        );
        localStorage.setItem("pinId", data.pinId);
        toast.success("You have successfully signed up.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
        handleEventTag(`seller_registeration`, data?.data?.id);
        navigate(`/verify-phone-number`);
        localStorage.setItem("userData", JSON.stringify(data.data));
      }
    } catch (error) {
      setLoading(false);
      console.log("register", error);
      if (!error.response.data.errors) {
        handleEventTag(`e_seller_registeration`);
        return navigate(`/no-connection`);
      }
      if (error.response.data.errors[0].field) {
        setFormattedErrors(
          error.response.data.errors.reduce(function (obj, err) {
            obj[err.field] = err.message;
            handleEventTag(`e_seller_registeration_${obj}`);
            return obj;
          }, {})
        );
      } else {
        console.log(error.response.data.errors[0].message);

        handleEventTag(
          `e_seller_registeration_${error.response.data.errors[0].message}`
        );
        setCustomError(error.response.data.errors[0].message);
        toast.error(`${error.response.data.errors[0].message}`, {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      }
    }
  };

  // useEffect(() => {}, [formattedErrors]);
  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                {t("Already_have_an_account")}?{" "}
                <Link to="/login" className="user-link">
                  {t("Login")}
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Register_as_a_Seller")}</h2>
                <p>
                  {" "}
                  {t("You_are_a_few_steps_away_from_creating_your_account")}
                </p>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("First_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="firstName"
                        value={sellerRegisterDetail.firstName}
                        onChange={handleChange}
                      />
                      {formattedErrors.firstName && (
                        <p className="errors">{formattedErrors.firstName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Last_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="lastName"
                        value={sellerRegisterDetail.lastName}
                        onChange={handleChange}
                      />
                      {formattedErrors.LastName && (
                        <p className="errors">{formattedErrors.LastName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Email_address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={sellerRegisterDetail.email}
                        onChange={handleChange}
                      />
                      {formattedErrors.email && (
                        <p className="errors">{formattedErrors.email}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Phone_number")}
                      </label>
                      <PhoneInput
                        onChange={setPhoneValue}
                        value={phoneValue}
                        name="phoneValue"
                        className="form-control"
                        defaultCountry="NG"
                      />
                      {formattedErrors.phoneNumber && (
                        <p className="errors">{formattedErrors.phoneNumber}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("Password")}
                      </label>
                      <div className="form-control passwordToggle">
                        <input
                          type={inputType}
                          id="exampleInputPassword1"
                          name="password"
                          value={sellerRegisterDetail.password}
                          onChange={handleChange}
                          onKeyUp={handleValidation}
                          required
                        />
                        <span
                          className={"password-icon"}
                          onClick={handlePasswordToggle}
                        >
                          {inputType === "password" ? (
                            <i className="far fa-eye-slash mt-1 pt-1"></i>
                          ) : (
                            <i className="far fa-eye mt-1 pt-1"></i>
                          )}
                        </span>
                      </div>
                      <p className="error-message">{passwordError}</p>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("How_did_you_hear_about_us")}?
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="howDidYouHearAboutUs"
                        value={sellerRegisterDetail.howDidYouHearAboutUs}
                        onChange={handleChange}
                      >
                        <option defaultValue="selected">
                          {t("Open_this_select_menu")}
                        </option>
                        <option value="AFCTCA">AFCFTA</option>
                        <option value="OKOBI">OKOBI</option>
                        <option value="FARMBENE">FARMBENE</option>
                        <option value="OTHERS">Others</option>
                      </select>
                      {formattedErrors.hearAboutUs && (
                        <p className="errors">{formattedErrors.hearAboutUs}</p>
                      )}
                    </div>
                    {customError && (
                      <p className="errors">{t("User_already_exist")}</p>
                    )}
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input custom-checkbox"
                        id="exampleCheck1"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        {t("I_agree_to_the")}{" "}
                        <Link to="/terms-of-use">{t("Terms_Conditions")}</Link>{" "}
                        {t("and")}{" "}
                        <Link to="/privacy">{t("Privacy_Policy")}</Link>
                      </label>
                    </div>

                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Signup")}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SellersRegistration;
